const COLORS = {
  primary: "#304cee", // main color
  dark1: "#f9f9fb", // actived side item background color
  dark2: "#f5f4f9", // background color
  dark3: "#e6e8f7", // top message background
  grey1: "#e2e2e4", // border color
  grey2: "#b1b1c4", // top message font color
};

export default COLORS;
