import styles from "./style";

const ChatForm = () => {
  return (
    <div style={styles.bottomMsgWrapperStyle}>
      <a
        href="https://instagram.com/scott_flear?igshid=ZjE2NGZiNDQ="
        rel="noopener noreferrer"
        target="_blank"
      >
        Built by Scott Flear based on the public works of Epictetus Discourses and Enchiridion
      </a>
    </div>
  );
};
export default ChatForm;
