import COLORS from "../../utils/colors";

const styles = {
  bottomMsgWrapperStyle: {
    textAlign: "center",
    padding: "5px 0px",
    // marginTop: 10,
    color: COLORS.grey2,
  },
  bottomMsgStyle: {
    display: "inline-blick",
    background: COLORS.dark3,
    padding: "2px 2px",
    borderRadius: 8,
  },
};

export default styles;
