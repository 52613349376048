import { createRef, useEffect, useRef, useState } from "react";
import { CopyOutlined } from "@ant-design/icons";
import { Space, message, Spin, Form, Input, Button } from "antd";
import { CopyToClipboard } from "react-copy-to-clipboard";
import styles from "./style";
import { useSelector, useDispatch } from "react-redux";
import { FiSend } from "react-icons/fi";
import { fetchMessage } from "../../actions/chatActions";

const ChatView = () => {
  const [copied, setCopied] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const messageRefs = useRef([]);
  const { messages, loading, error } = useSelector((state) => state.chat);

  const [chatForm] = Form.useForm();
  const messageInputRef = useRef(null);
  const dispatch = useDispatch();
  const onSubmit = (event) => {
    if (!event.target.value) {
      return;
    }
    dispatch(fetchMessage(event.target.value));
    chatForm.resetFields();
  };
  useEffect(() => {
    if (!loading) {
      // To run in next cycle.
      setTimeout(() => {
        messageInputRef.current.focus();
      }, 0);
    }
  }, [loading]);

  messageRefs.current = [...Array(messages.length).keys()].map(
    (_, i) => messageRefs.current[i] ?? createRef()
  );
  useEffect(() => {
    if (copied) {
      messageApi
        .open({
          type: "success",
          content: "Copied successfully!",
        })
        .then(() => {
          setCopied(false);
        });
    }
  }, [copied]);
  useEffect(() => {
    if (messages.length) {
      // change scroll in next cycle
      setTimeout(() => {
        messageRefs.current[messages.length - 1].current.scrollIntoView({
          behavior: "smooth",
        });
      }, 1);
    }
    if (error && !loading) {
      messageApi.open({
        type: "error",
        content: error,
      });
    }
  }, [messages, error, loading]);
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        padding: "0px 40px",
        overflowY: "auto",
      }}
    >
      <div style={messages.length === 0 ? styles.chatViewStyle : styles.nextChatViewStyle}>
        {contextHolder}
        <div style={styles.topMsgWrapperStyle}>
          <span style={styles.topMsgStyle}>
            Ask Epictetus any question you want about Stoicism & mindset
          </span>
        </div>
        {messages.map((msg, idx) => (
          <div key={idx}>
            <div
              key={msg.id}
              ref={messageRefs.current[idx]}
              style={msg.type === "a" ? null : styles.sentMsgContainerStyle}
            >
              <Space
                style={msg.type === "a" ? styles.receivedMsgStyle : styles.sentMsgStyle}
                align="start"
              >
                <div style={styles.msgTextStyle}>{msg.text}</div>
                {msg.type === "a" ? (
                  <CopyToClipboard onCopy={() => setCopied(true)} text={msg.text}>
                    <CopyOutlined />
                  </CopyToClipboard>
                ) : (
                  <></>
                )}
              </Space>
            </div>
            <br />
          </div>
        ))}
        {loading ? <Spin /> : <></>}
      </div>
      <div style={messages.length === 0 ? styles.initStyle : styles.nextStyle}>
        <Form layout="inline" form={chatForm} name="message-form" style={styles.formStyle}>
          <Form.Item style={styles.inputStyle} name="message">
            <Input
              size="large"
              placeholder="Message"
              disabled={loading}
              onPressEnter={onSubmit}
              ref={messageInputRef}
              suffix={
                <Button
                  disabled={loading}
                  onClick={() => {
                    dispatch(fetchMessage(messageInputRef.current.input.value));
                    chatForm.resetFields();
                  }}
                >
                  <FiSend />
                </Button>
              }
            />
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default ChatView;
