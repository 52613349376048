import { Layout } from "antd";
import ChatForm from "../../components/ChatForm";
import ChatView from "../../components/ChatView";
import styles from "./style";
const { Header, Footer, Content } = Layout;

const CGTLayout = () => (
  <Layout style={styles.layoutStyle}>
    <Layout>
      <Header style={styles.headerStyle}>
        <img style={{ marginTop: 15 }} width={140} height={35} src="Epictetusai.png" />
      </Header>
      <Content style={styles.contentStyle}>
        <ChatView />
      </Content>
      <Footer style={styles.footerStyle}>
        <ChatForm />
      </Footer>
    </Layout>
  </Layout>
);
export default CGTLayout;
